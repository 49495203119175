const breakpointOrder = ['xs', 'sm', 'md', 'lg', 'xl'];

// Make sure to keep this up to date with the Perl implementation in
// lib/JIX/Mojo/Plugin/DesignComponents.pm
export function borderRadius({ numItems, cols = { xs: 1 }, cornerType = 'md' }) {
    const res = Array.from(Array(numItems), () => []);

    for (const breakpoint of breakpointOrder) {
        let numCols = cols[breakpoint];
        if (!numCols) {
            continue;
        }
        numCols = Math.min(numCols, numItems);

        const perCol = Math.trunc(numItems / numCols);
        const remainder = numItems % numCols;

        const itemsPerCol = new Array(numCols).fill(perCol);
        for (let i = 0; i < remainder; ++i) {
            itemsPerCol[i]++;
        }

        for (let col = 0; col < numCols; ++col) {
            for (let row = 0; row < itemsPerCol[col]; ++row) {
                const i = row * numCols + col;

                const corners = new Set();
                if (row === 0) {
                    if (col === 0) {
                        corners.add('top-left');
                    }
                    if (col === numCols - 1) {
                        corners.add('top-right');
                    }
                }
                if (row === itemsPerCol[col] - 1) {
                    if (col === 0) {
                        corners.add('bottom-left');
                    }
                    if (col === numCols - 1 || itemsPerCol[col] > itemsPerCol[col + 1]) {
                        corners.add('bottom-right');
                    }
                }

                const cornersInverse = new Set();
                ['top-left', 'top-right', 'bottom-left', 'bottom-right'].forEach(x => {
                    if (!corners.has(x)) {
                        cornersInverse.add(x);
                    }
                });

                _simplifyCorners(corners);
                _simplifyCorners(cornersInverse);

                for (const corner of [...corners.keys()].sort()) {
                    let className = 'rounded';
                    if (breakpoint !== 'xs') {
                        className += `-${breakpoint}`;
                    }
                    className += `-${corner}`;
                    if (cornerType !== 'md') {
                        className += `-${cornerType}`;
                    }

                    res[i].push(className);
                }
                for (const corner of [...cornersInverse.keys()].sort()) {
                    let className = 'rounded';
                    if (breakpoint !== 'xs') {
                        className += `-${breakpoint}`;
                    }
                    className += `-${corner}`;
                    className += '-0';

                    res[i].push(className);
                }
            }
        }
    }

    return res;
}

function _simplifyCorners(corners) {
    if (corners.has('top-left') && corners.has('top-right')) {
        corners.add('top');
        corners.delete('top-left');
        corners.delete('top-right');
    }
    if (corners.has('bottom-left') && corners.has('bottom-right')) {
        corners.add('bottom');
        corners.delete('bottom-left');
        corners.delete('bottom-right');
    }
    if (corners.has('bottom-left') && corners.has('top-left')) {
        corners.add('left');
        corners.delete('bottom-left');
        corners.delete('top-left');
    }
    if (corners.has('bottom-right') && corners.has('top-right')) {
        corners.add('right');
        corners.delete('bottom-right');
        corners.delete('top-right');
    }
    if (corners.has('left') && corners.has('right') || corners.has('top') && corners.has('bottom')) {
        corners.clear();
        corners.add('all');
    }
    return corners;
}
